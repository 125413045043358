* {
  padding: 0;
  margin: 0;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.fnt-fmly-popins{
  font-family: Poppins !important;
}
.primary-text-color{
  background: linear-gradient(91.79deg, #0298D3 34.75%, #1E293A 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.logo-text{
  background: linear-gradient(91.79deg, #0298D3 34.75%, #1E293A 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.login-btn{
  border: 2px solid #0298D3;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 0.8rem;
}
.active{
  border-bottom: 2px solid #0298D3;
}
.nav-link{
  font-weight: 600;
  color: black;
}
.nav-link:hover{
  border-bottom: 2px solid #0298D3 !important;
}
.gradient-text {
  background-image: linear-gradient(to right, #80007F, #A88D00);
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  cursor: pointer;
}
.gradient-btn-1 {
  background-image: linear-gradient(to right, #80007F, #A88D00); /* Adjust colors as needed */
  color: white; /* Text color */
  padding: 10px 20px; /* Adjust padding as needed */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius for rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 4rem;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; 
}
.gradient-btn-2 {
  background-image: linear-gradient(to right, #80007F, #A88D00); /* Adjust colors as needed */
  color: white; /* Text color */
  padding: 10px 20px; /* Adjust padding as needed */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius for rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  border-radius: 4rem;
  display: none; /* Ensure button respects text-align */
}
@media only screen and (max-width: 767px) {
  .ebook-content{
    padding-top: 22rem !important;
  }
  .ebook-img{
    display: block !important;
    top: 12rem;
    left: 5rem;
    width: 20rem;
    height: 20rem;
  }
  .gradient-btn-2{
    display: block;
    width: 100%;
    margin-bottom: 2rem;
  }
  .gradient-btn-1{
    display: none;
    width: 100%;
  }
}